import { useEffect, useState } from "react";

import pbbr from "../assets/img/pbbr/pbbr.png";
import pbbr2 from "../assets/img/pbbr/pbbr2.png";
import pbbr_dragon from "../assets/img/pbbr/pbbr_dragon.png";
import pbbr_db from "../assets/img/pbbr/pbbr_db.webp";

import pff_freestyle from "../assets/img/pff/pff_freestyle.png";
import pff_freestyle2 from "../assets/img/pff/pff_freestyle2.webp";

import HareGames from "../assets/img/haregames/HareGames.webp";
import HareGamesBG from "../assets/img/haregames/HareGamesBG.png";
import HareGamesBGZombies from "../assets/img/haregames/HareGamesBGZombies.png";

import { Element } from "react-scroll";

export default function Games() {
  const gameImages = {
    pff: [pff_freestyle, pff_freestyle2],
    pbbr: [pbbr, pbbr2, pbbr_dragon, pbbr_db],
    hare: [HareGames, HareGamesBG, HareGamesBGZombies]
  };

  const [currentImageIndex, setCurrentImageIndex] = useState({
    pff: 0,
    pbbr: 0,
    hare: 0
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => ({
        pff: (prevIndex.pff + 1) % gameImages.pff.length,
        pbbr: (prevIndex.pbbr + 1) % gameImages.pbbr.length,
        hare: (prevIndex.hare + 1) % gameImages.hare.length
      }));
    }, 3000); // change image every 3 seconds

    return () => clearInterval(interval);
  }, [gameImages]);

  return (
    <section className="my-20" id="mini_game">
      <div className="text-center flex flex-col p-6 m-4">
        <Element name="title_game" id="title_game">
          <h3 className="font-bold text-white text-3xl">
            3 New Mini Games Created With Many More to Come
          </h3>
        </Element>
        <Element name="text_game" id="text_game">
          <p className="text-slate-500 pb-10 pt-3">
            Dive into the excitement of our new P2E Mini Games! Compete for big
            prizes against other players and turn your gaming skills into
            valuable rewards. Join the fun now!
          </p>
        </Element>
      </div>
      <div className="flex flex-wrap justify-center ">
        <GameCarousel
          images={gameImages.pff}
          currentIndex={currentImageIndex.pff}
          href="https://pff.polarfi.io/"
          title="PFF: PolarFi Freestyle"
        />
        <GameCarousel
          images={gameImages.pbbr}
          currentIndex={currentImageIndex.pbbr}
          href="https://pbbr.polarfi.io/"
          title="PolarBear Battle Royale"
        />
        <GameCarousel
          images={gameImages.hare}
          currentIndex={currentImageIndex.hare}
          href="https://haregames.polarfi.io/"
          title="HareGames"
        />
      </div>
    </section>
  );
}

function GameCarousel({ images, currentIndex, href, title }) {
  return (
    <Element className="mx-5 my-5">
      <div className="relative group w-96 h-72">
        <a href={href}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={title}
              className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-700 ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
          <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
            <div className="text-center">
              <p className="text-white font-bold">Play the game</p>
              <svg
                className="w-12 h-12 mx-auto fill-current text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </div>
          </div>
        </a>
      </div>
      <p className="text-white">{title}</p>
    </Element>
  );
}
